@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Used For Chrome, Opera and Safari */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Used For Firefox: */
  .no-scrollbar {
    scrollbar-width: none;
  }
  /* Used For MS Edge and IE: */
  .no-scrollbar {
    -ms-overflow-style: none;
  }
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../assets/fonts/Inter-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: Inter, sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

*,
button,
input {
  border: 0;
  outline: 0;
  background: transparent;

  font-family: Inter, sans-serif;
}

.react-calendar__navigation {
  display: block;
  background-color: #fff4df;
  height: 30px !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: 0px !important;
  text-align: center;
  font-size: 14px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  color: #000;
  font-size: 20px;
  margin-right: 5px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  color: #000;
  font-size: 20px;
  margin-left: 5px;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #000;
}

.react-calendar__viewContainer {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: white;
  font-size: 14px;
}

.react-calendar__month-view__weekdays__weekday {
  color: #dc7e19;

  abbr[title] {
    text-decoration: none !important;
  }
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  padding: 4px;
}

.react-calendar__tile--active {
  background-color: #dc7e19;
  border-radius: 4px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
